export const ar = {
  caption: {
    all_request: 'جميع طلبات الصيانة',
    detail_request: 'تفاصيل طلب صيانة',
    card_detail: {
      location: 'موقع',
      description: 'وصف',
      overal_rates: 'معدلات مفرطة',
    },
    attachments: 'المرفقات',
    photos: 'صور',
    project_approved: 'المشروع المعتمد',
    maximum_evaluator: 'الرجاء إدخال أقصى تقييم للمشروع',
    evaluator_assigned: 'تم تعيين المقيِّم',
    project_status: 'الحالة المشروع',
    all_evaluation_request: 'جميع طلب التقييم',
    detail_evaluation_request: 'طلب تقييم التفاصيل',
    evaluation: 'تقييم',
    project_evaluated: 'تقييم المشروع',
    my_evaluation_task: 'مهمتي التقييمية',
    step: 'خطوة',
    all_mosques_evaluated: 'تقييمات جمع المساجد',
    detail_mosques_evaluated: 'تفاصيل مسجد تقييم',
  },
  form: {
    evaluator_for_project: {
      label: 'المقيم للمشروع',
      placeholder: 'على سبيل المثال 10 المقيِّم',
      required: 'يجب عليك إدخال أقصى تقييم',
      min: 'الرجاء إدخال المقيِّم أكبر من 0',
    },
    mosque_name: {
      label: 'اسم المسجد',
    },
    locations: {
      label: 'المواقع',
    },
    request_date: {
      label: 'تاريخ الطلب',
    },
    evaluate_date: {
      label: 'تقييم التاريخ',
    },
    selection: {
      label: 'حدد الخيار الخاص بك',
      required: 'يجب عليك تحديد أحد الخيارات',
      options: {
        very_poor: 'فقير جدا (1-2)',
        poor: 'الفقراء (3-4)',
        fair: 'معرض (5-6)',
        good: 'جيد (7-8)',
        excellent: 'ممتاز (9-10)',
        necessary: 'ضروري',
        unnecessary: 'غير ضروري',
      },
    },
    image: {
      label: 'تحميل الصورة',
      required: 'يجب عليك إضافة صورة واحدة واحدة',
    },
    prayer_hall: {
      label: 'قاعة الصلاة',
    },
    wet_areas: {
      label: 'المناطق الرطبة',
    },
    minaret: {
      label: 'مئذنة',
    },
    exterior_walls: {
      label: 'الجدران الخارجية',
    },
    foundation: {
      label: 'مؤسسة',
    },
    roof_inspection_and_repair: {
      label: 'عمليات تفتيش السقف والإصلاح',
    },
    floor: {
      label: 'أرضية',
    },
    walls_and_ceilings: {
      label: 'الجدران والسقوف',
    },
    windows_and_doors: {
      label: 'النوافذ والأبواب',
    },
    electrical_system: {
      label: 'النظام الكهربائي',
    },
    fire_safety: {
      label: 'السلامة من الحرائق',
    },
    lighting: {
      label: 'إضاءة',
    },
    finishes_and_decorations: {
      label: 'التشطيبات والزخارف',
    },
    temperature: {
      label: 'درجة الحرارة',
    },
    architectural: {
      label: 'المعماري',
    },
    operational: {
      label: 'التشغيلية',
    },
    humidity: {
      label: 'الرطوبة',
    },
    special_water_conditions: {
      label: 'دورات المياه',
    },
    factor_type: {
      label: 'عامل',
    },
    images: {
      label: 'الصور',
    },
    expected_cost: {
      label: 'ما هي التكلفة المتوقعة؟',
      placeholder: 'على سبيل المثال 100',
      min_1: 'يجب أن يكون أكبر من 1',
      required: 'التكلفة المتوقعة مطلوبة',
    },
  },
  button: {
    approve_request: 'الموافقة على الطلب',
    reject_request: 'رفض الطلب',
    apply_for_evaluation: 'التقدم بطلب للتقييم',
    archive_evaluation: 'أرشفة هذا التقييم',
    add_photos: 'أضف الصور',
    accept_request: 'قبول الطلب',
    approve_evaluation: 'الموافقة على التقييم',
    reject_evaluation: 'رفض التقييم',
    save_changes: 'حفظ التغييرات',
  },
};
